import AV from 'leancloud-storage';
const getPatientsGlsMedian = async (data) => {
    return await AV.Cloud.run('getPatientsGlsMedian', data);
}

const getPatientsTirAndCvRecordMedian = async (data) => {
    return await AV.Cloud.run('getPatientsTirAndCvRecordMedian', data);
}

const getMainDiagnosis = async (data) => {
    const query = new AV.Query('MainDiagnosis');
    query.limit(1000);
    let res = await query.find();
    return JSON.parse(JSON.stringify(res));
}

const getPatientTirAndCvRecordMedian = async (data) => {
    return await AV.Cloud.run('getPatientTirAndCvRecordMedian', data);
}

export default {
    getPatientsGlsMedian,
    getPatientsTirAndCvRecordMedian,
    getMainDiagnosis,
    getPatientTirAndCvRecordMedian
}