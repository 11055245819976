import AV from 'leancloud-storage';
let query;
let flag = 1;
let num = /^[0-9]*$/;
import storage from "../storage/storage.js";
let users;
const historyRecord = async (data) => {
    console.log(data)
    users = storage.getItem("users");
    let objectIds = []
    const query2 = new AV.Query('Patient');
    if (data.name) {
        if (num.test(data.name)) {
            flag = 1;
            query2.startsWith('record_number', data.name);
            query2.contains('record_number', data.name);
            let res = await query2.find();
            if (res) {
                for (const key in res) {
                    query = new AV.Query('HistoryRecord');
                    const post = AV.Object.createWithoutData('HistoryRecord', res[key].toJSON().objectId);
                    objectIds.push(post)
                }
                query.containedIn('patient', objectIds);
            }
        } else {
            //操作医生
            flag = 2;
        }
    } else {
        query = new AV.Query('HistoryRecord');
    }
    query.descending('updatedAt');
    query.include('patient')
    query.include('hospital')
    query.include('user.hospital')
    if (flag == 2) {
        let User = new AV.Query('_User');
        User.contains('realName', data.name)
        query.matchesQuery("user", User)
    }
    if (users && users.hospital) {
        const queryHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
        query.equalTo('hospital', queryHospital);
    }
    if (data.date != null && data.date.length >= 2) {
        console.log(data)
        query.greaterThanOrEqualTo('createdAt', new Date(data.date[0]));
        query.lessThan('updatedAt', new Date(data.date[1]));
    }
    flag = ''
    query.limit(data.pagination.pageSize);
    if (data.isSkip == 1) {
        query.skip(data.pagination.pageSize * (data.pagination.current - 1))
    }
    let count = await query.count()
    let res = await query.find();
    for (const key in res) {
    }
    return {
        count: count,
        data: JSON.parse(JSON.stringify(res))
    }
}


const downloadPatientHistory = async (data) => {
    console.log(data);
    users = storage.getItem("users");
    return await AV.Cloud.run('downloadPatientHistory1', {
        name: data && data.name ? data.name : '',
        user: users && users.hospital ? users.hospital.objectId : '',
        startTime: data && data.date != null ? data.date[0] : undefined,
        endTime: data && data.date != null ? data.date[1] : undefined,
    });
}


const getTask = async (data) => {
    console.log(data)
    const query = new AV.Query('Task');
    query.equalTo("objectId", data);
    let res = await query.find();
    return {
        data: JSON.parse(JSON.stringify(res))
    }
}

export default {
    historyRecord,
    downloadPatientHistory,
    getTask
}