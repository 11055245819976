import AV from 'leancloud-storage';
import {
    message
} from 'ant-design-vue';
import storage from "../storage/storage.js";
const Todo = AV.Object.extend('AccessManagement');
// 创建数据
let users;
const addAuthority = async (data) => {
    users = storage.getItem("users");
    console.log(users)
    if (users && users.hospital) {
        // 医院账号
        console.log('市医院')
        const todo = new Todo();
        const role = new AV.Query('_Role');
        role.equalTo('name', 'admin')
        role.equalTo('name', 'hospital')
        let roleInfo = await role.first()
        const roleAcl = new AV.ACL();
        roleAcl.setPublicReadAccess(true);
        roleAcl.setWriteAccess(roleInfo, true);
        todo.setACL(roleAcl)
        todo.set('name', data.name);
        todo.set('access_list', data.access_list);
        const setHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
        todo.set('hospital', setHospital);
        todo.set('hospitalId', users.hospital.objectId);
        return todo.save()
    } else {
        // 总账号
        const todo = new Todo();
        const role = new AV.Query('_Role');
        console.log(role)
        role.equalTo('name', 'admin')
        let roleInfo = await role.first()
        const roleAcl = new AV.ACL();
        roleAcl.setPublicReadAccess(true);
        roleAcl.setWriteAccess(roleInfo, true);
        todo.setACL(roleAcl)
        todo.set('name', data.name);
        todo.set('access_list', data.access_list);
        todo.set('hospitalId', '1');
        return todo.save()
    }

}
// 查询数据
const getAuthority = async (data) => {
    console.log("asc")
    users = storage.getItem("users");
    console.log(users)
    const query = new AV.Query('AccessManagement',);
    if (data.name && data.name != undefined) {
        let keys = Object.keys(data)[0]
        let val = Object.values(data)[0]
        if (val && keys == 'name') {
            query.startsWith(keys + "", val);
            query.contains(keys + "", val);
        } else if (val && keys == 'objectId') {
            query.equalTo(keys + "", val);
        }

    }
    console.log(users)
    if (users && users.hospital) {
        console.log("普通医院")
        const queryHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
        query.equalTo('hospital', queryHospital);
    } else {
        query.equalTo('hospitalId', '1');
    }
    query.limit(data.pagination.pageSize);
    query.skip(data.pagination.pageSize * (data.pagination.current - 1))
    let count = await query.count()
    let res = await query.find();
    return {
        count: count,
        data: JSON.parse(JSON.stringify(res))
    }
}
// 修改数据
const amendAuthority = (data) => {
    users = storage.getItem("users");
    console.log(users)
    let obj = JSON.parse(JSON.stringify(data))
    const todo = AV.Object.createWithoutData('AccessManagement', obj.objectId);
    todo.set('name', data.name);
    todo.set('access_list', data.access_list);
    if (users && users.hospital) {
        console.log("普通医院")
        const setHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
        todo.set('hospital', setHospital);
        todo.set('hospitalId', users.hospital.objectId);
    } else {
        todo.set('hospitalId', '1');
    }
    return todo.save();
}
// 删除列表
const deleteAuthority = async (data) => {
    const priorityOne = new AV.Query('_User');
    const todo = AV.Object.createWithoutData('AccessManagement', data.objectId)
    priorityOne.equalTo('access_id', todo);
    let count = await priorityOne.count()
    let destroy;
    if (count < 1) {
        destroy = await todo.destroy()
    } else {
        return message.error('已有账号使用此权限,无法删除!');
    }
    return {
        count: count,
        destroy: destroy
    }
}


export default {
    addAuthority,
    getAuthority,
    amendAuthority,
    deleteAuthority
}