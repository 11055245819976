import Vue from 'vue'
import App from './App.vue'
import '../src/leancloud/leancloud.js'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import echarts from 'echarts'
import router from './router/router'
import store from '@/store'
import flexilbe from './flexible/flexible'
Vue.prototype.$echarts = echarts
Vue.use(Antd, flexilbe)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')