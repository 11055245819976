import AV from 'leancloud-storage';

// 医嘱执行率排名
const getHospitalExecutionRanking = async (data) => {
    const query = new AV.Query('HospitalExecutionRanking');
    const queryHospital = AV.Object.createWithoutData('Hospital', data.hospitalId);
    query.equalTo('hospital', queryHospital);
    query.include('user');
    query.ascending("rate");
    let res = await query.find();
    return JSON.parse(JSON.stringify(res));
}

// 医嘱执行率
const getHospitalExecutionRate = async (data) => {
    const query = new AV.Query('HospitalExecutionRate');
    const queryHospital = AV.Object.createWithoutData('Hospital', data.hospitalId);
    query.equalTo('hospital', queryHospital);
    if (data.startTime != null || data.endTime != null) {
        query.greaterThanOrEqualTo('day', data.startTime);
        query.lessThan('day', data.endTime);
    }
    query.ascending("day");
    let res = await query.find();
    return JSON.parse(JSON.stringify(res));
}

// 采纳率
const getHospitalAdoptRate = async (data) => {
    const query = new AV.Query('HospitalAdoptRate');
    const queryHospital = AV.Object.createWithoutData('Hospital', data.hospitalId);
    query.equalTo('hospital', queryHospital);
    if (data.startTime != null || data.endTime != null) {
        query.greaterThanOrEqualTo('day', data.startTime);
        query.lessThan('day', data.endTime);
    }
    query.ascending("day");
    let res = await query.find();
    return JSON.parse(JSON.stringify(res));
}

export default {
    getHospitalExecutionRanking,
    getHospitalExecutionRate,
    getHospitalAdoptRate
}