import AV from 'leancloud-storage';
import storage from "../storage/storage.js";
let users;
const patientDownload = async (data) => {
    users = storage.getItem("users");
    console.log(users)
    console.log(data)
    return await AV.Cloud.run('downloadPatientStaticInfo', {
        name: data && data.name ? data.name : '',
        user: users && users.hospital ? users.hospital.objectId : '',
    });

    // const query = new AV.Query('Patient');
    // if (users && users.hospital) {
    //     const queryHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
    //     query.equalTo('hospital', queryHospital);
    // }
    // if (data.name) {
    //     query.startsWith('record_number', data.name);
    //     query.contains('record_number', data.name);
    // }
    // query.include('icu_diagnosis')
    // query.descending("createdAt")
    // query.include('hospital')
    // let count = await query.count()
    // query.limit(count);
    // let res = await query.find();
    // return {
    //     count: count,
    //     data: JSON.parse(JSON.stringify(res))
    // }
}
export default {
    patientDownload
}