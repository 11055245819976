import AV from 'leancloud-storage';
let users;
import storage from "../storage/storage.js";
const healthcare = async (data) => {
    users = storage.getItem("users");
    // return AV.Cloud.run('getRoleUserList')
    return await AV.Cloud.run('getRoleUserList', {
        page: data.current,
        pageSize: data.pageSize,
        role_name: "nurse",
        hospitalId: users && users.hospital ? users.hospital.objectId : "",
    });
}
export default {
    healthcare
}