import AV from 'leancloud-storage';
const login = (userInfo) => {
    return AV.User.logIn(userInfo.username, userInfo.password)
}
const logout = () => {
    return AV.User.logOut()
}

//获取医院
const getHospitals = async (data) => {
    const query = new AV.Query('Hospital');
    query.notEqualTo("disabled", true);
    let res = await query.find();
    return {
        data: JSON.parse(JSON.stringify(res))
    }
}

// 查询数据
const getLoginAuthority = async (data) => {
    const query = new AV.Query('_User');
    query.equalTo("objectId", data);
    query.include('access_id')
    return query.find()
}


export default {
    login,
    getHospitals,
    getLoginAuthority,
    logout,
}