import AV from 'leancloud-storage';
import storage from "../storage/storage.js";
let users;
const patient = async (data) => {
    users = storage.getItem("users");
    const query = new AV.Query('Patient');
    if (users && users.hospital) {
        const queryHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
        query.equalTo('hospital', queryHospital);
    }
    if (data.hospitalId) {
        const queryHospital = AV.Object.createWithoutData('Hospital', data.hospitalId);
        query.equalTo('hospital', queryHospital);
    }
    if (data.name) {
        query.startsWith('record_number', data.name);
        query.contains('record_number', data.name);
    }
    query.limit(data.pagination.pageSize);
    query.skip(data.pagination.pageSize * (data.pagination.current - 1))
    query.include('icu_diagnosis')
    query.descending("createdAt")
    query.include('hospital')
    let count = await query.count()
    let res = await query.find();
    return {
        count: count,
        data: JSON.parse(JSON.stringify(res))
    }
}

const patient2 = async (data) => {
    users = storage.getItem("users");
    const query = new AV.Query('Patient');
    if (users && users.hospital) {
        const queryHospital = AV.Object.createWithoutData('Hospital', users.hospital.objectId);
        query.equalTo('hospital', queryHospital);
    }
    if (data.name) {
        query.startsWith('record_number', data.name);
        query.contains('record_number', data.name);
    }
    query.include('icu_diagnosis')
    query.descending("createdAt")
    query.include('hospital')
    let count = await query.count()
    query.limit(count);
    let res = await query.find();
    return {
        count: count,
        data: JSON.parse(JSON.stringify(res))
    }
}
export default {
    patient,
    patient2
}