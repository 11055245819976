import AV from 'leancloud-storage';
// 获取列表
const getHospitalList = async (data) => {
    return await AV.Cloud.run('getHospitalList', data);
}

// 创建医院
const createHospital = async (data) => {
    return await AV.Cloud.run('createHospital', data);
}

// 编辑医院
const updateHospital = async (data) => {
    return await AV.Cloud.run('updateHospital', data);
}

// 医院状态（删除）
const updatedHospitalStatus = async (data) => {
    return await AV.Cloud.run('updatedHospitalStatus', data);
}

// 重置密码
const updateHospitalPassword = async (data) => {
    return await AV.Cloud.run('updateHospitalPassword', data);
}

export default {
    getHospitalList,
    createHospital,
    updateHospital,
    updateHospitalPassword,
    updatedHospitalStatus
}