import AV from 'leancloud-storage';
// 创建数据
let users;
import storage from "../storage/storage.js";
const addAccount = async (data) => {
    users = storage.getItem("users");
    const relevance = AV.Object.createWithoutData('AccessManagement', data.access);
    let userInfo = {
        role_name: users && users.hospital ? 'hospital' : "admin",
        hospitalId: users && users.hospital ? users.hospital.objectId : "",
        access_id: relevance.toJSON().objectId,
        username: data.account,
        password: data.password,
        name: data.name,
        phone: data.phone,
        qsCode: data.qsCode.length > 0 ? data.qsCode[0].url : '',
        qsCodeId: data.qsCode.length > 0 ? data.qsCode[0].uid : '',
        exclusiveCode: data.code,
    };
    return await AV.Cloud.run('updateUserInCMS', {
        userInfo
    });
}
// 查询数据
const getAccount = async (data) => {
    users = storage.getItem("users");
    return await AV.Cloud.run('getRoleUserList', {
        page: data.pagination.current,
        pageSize: data.pagination.pageSize,
        role_name: users && users.hospital ? 'hospital' : "admin",
        hospitalId: users && users.hospital ? users.hospital.objectId : "",
        username: data.username
    });
}
// 修改数据
const amendAccount = async (data) => {
    users = storage.getItem("users");
    const relevance = AV.Object.createWithoutData('AccessManagement', data.access);
    let userInfo = {
        role_name: users && users.hospital ? 'hospital' : "admin",
        hospitalId: users && users.hospital ? users.hospital.objectId : "",
        access_id: relevance.toJSON().objectId,
        objectId: data.objectId,
        username: data.account,
        password: data.password,
        name: data.name,
        mobilePhoneNumber: data.phone,
        qsCode: data.qsCode.length > 0 ? data.qsCode[0].url : '',
        qsCodeId: data.qsCode.length > 0 ? data.qsCode[0].uid : '',
    };
    return await AV.Cloud.run('updateUserInCMS', {
        userInfo
    });
}
// 删除列表
const deleteAccount = async (data) => {
    return await AV.Cloud.run('deleteUserInCMS', {
        objectId: data.objectId,
    });
}

// 查询身份权限表
const getIdentity = (data) => {
    console.log(data)
    users = storage.getItem("users");
    console.log(users)
    const query = new AV.Query('AccessManagement', );
    if (users && users.hospital) {
        query.equalTo('hospitalId', users.hospital.objectId)
    } else {
        query.equalTo('hospitalId', '1')
    }
    query.skip(0);
    return query.find()
}

export default {
    addAccount,
    getAccount,
    amendAccount,
    getIdentity,
    deleteAccount
}