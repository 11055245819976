import AV from 'leancloud-storage';
const sickinquire = (id, start, end) => {
    const query = new AV.Query('GlsRecord');
    if (Array.isArray(id)) {
        let patientIDs = id.map(item => AV.Object.createWithoutData('Patient', item))
        AV.Object.createWithoutData('Patient', patientIDs);
        query.containedIn("patient", patientIDs)
    } else {
        query.equalTo('patient', AV.Object.createWithoutData('Patient', id));
        if (start != null || end != null) {
            query.greaterThanOrEqualTo('createdAt', new Date(start));
            query.lessThan('updatedAt', new Date(end));
        }
    }
    return query.find()
}
export default {
    sickinquire
}