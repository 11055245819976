import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading:false,
    },

    mutations: {
          setStatus(state,info){
        console.log(info,5443)
         state.loading=info;
    },
    },
  getters,
    actions: {},
    modules: {}
})