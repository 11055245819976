<template>
  <div class="login">
    <div class="content">
      <div class="title">CMS登录系统</div>
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-form-item label="账号:">
          <a-input
            type="text"
            v-decorator="[
              'username',
              {
                rules: [{ required: true, message: '请输入您的账号' }],
              },
            ]"
            placeholder="请输入账号"
          />
        </a-form-item>
        <a-form-item label="密码:">
          <a-input
            type="password"
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入您的密码' }],
              },
            ]"
            placeholder="请输入密码"
          />
        </a-form-item>
        <a-checkbox @change="checkedps" v-model="ischecked">记住密码</a-checkbox>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit">登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
export default {
  name: "login",
  props: {},
  data() {
    return {
      access_list: [],
      user: "",
      admin_access_list: [
        "healthcare",
        "healthcare-checkList",
        "authorityManagement",
        "authorityManagement-addBtn",
        "authorityManagement-compileBtn",
        "authorityManagement-deleteBtn",
        "authorityManagement-examineBtn",
        "accountManagement-addBtn",
        "accountManagement",
        "accountManagement-compileBtn",
        "accountManagement-deleteBtn",
        "patientinformation-checkList",
        "patientinformation-statisticsBtn",
        "patientinformation-downloadBtn",
      ],
      ischecked: true,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  mounted() {
    if (storage.getItem("username") && storage.getItem("password") != null) {
      this.form.setFieldsValue({
        username: storage.getItem("username"),
        password: storage.getItem("password"),
      });
    }
  },
  methods: {
    checkedps() { },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          api
            .login(values)
            .then((res) => {
              console.log(res);
              storage.setItem("users", res);
              let objectId = res.toJSON().objectId;
              this.user = res.toJSON().username;
              api.getLoginAuthority(objectId).then((res) => {
                this.access_list = res[0].toJSON().access_id.access_list;
              });
              if (this.access_list) {
                setTimeout(() => {
                  if(this.user == 'admin'){
                    this.access_list.push('hospitalmanagement');
                  }
                  storage.setItem("getJurisdiction", this.access_list); //路由权限
                  this.getrouter();
                }, 500);
              }
              storage.setItem("account", values.username);
              this.remember(values);
              this.$message.success("登录成功");
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("登录失败");
            });
        }
      });
    },
    remember(values) {
      if (this.ischecked) {
        //记住账号密码
        storage.setItem("username", values.username);
        storage.setItem("password", values.password);
        this.form.setFieldsValue({
          username: values.username,
          password: values.password,
        });
      } else {
        storage.removeItem("username");
        storage.removeItem("password");
      }
    },

    // 获取添加路由表
    getrouter() {
      let jurisdictionRoutes = [];
      let childrens = [];
      this.access_list.forEach((item) => {
        jurisdictionRoutes.push(item.split("-")[0]);
      });
      [...new Set(jurisdictionRoutes)].forEach((item) => {
        // 无父级目录页面
        if (item == 'patientmanagement' || item == 'dataanalysis' || item == 'systemSettings') {
          return;
        } else {
          childrens.push({
            path: "/" + item,
            name: item,
            component: (resolve) => require([`../${item}/${item}.vue`], resolve),
            meta: {
              title: "",
              requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
            },
          });
        }
      });
      let jurisdiction = [...new Set(jurisdictionRoutes)];
      jurisdiction.sort(function (a, b) {
        // order是规则  jurisdiction是需要排序的数组 
        var order = [
          "healthcare",
          "patientinformation",
          "patientinoperation",
          "patientstaticinformation",
          "bloodglucosequality",
          "behavioranalysis",
          "hospitalmanagement",
          "accountManagement",
          "authorityManagement",
        ];
        return order.indexOf(a) - order.indexOf(b);
      });
      let routes = [];
      routes.push({
        path: "/home",
        name: "home",
        component: () => import("../home/home"),
        // redirect: {
        //   name:
        //     this.user == 'admin'
        //       ? 'healthcare'
        //       : [...new Set(jurisdictionRoutes)][0],
        // },
        meta: {
          title: "",
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },
        children: childrens,
      });
      this.$router.options.routes.push(...routes);
      this.$router.addRoutes(this.$router.options.routes);
      let routers = "home";
      if (this.access_list.length > 0) {
        for (let i = 0; i < jurisdiction.length; i++) {
          if (jurisdiction[i] != 'patientmanagement' && jurisdiction[i] != 'dataanalysis' && jurisdiction[i] != 'systemSettings') {
            routers = jurisdiction[i];
            break;
          }
        }
      }
      this.$router.push(`/${routers}`);
      storage.setItem("getRoutes", JSON.stringify(routes));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../../assets/image/login_bg.jpg") center/cover no-repeat;
  background-size: cover;
}
.content {
  border-radius: 6px;
  background: rgb(255, 255, 255);
  width: 400px;
  height: 350px;
  padding: 25px 25px 5px 25px;
}
.content >>> .ant-form-item-label > label {
  color: #707070;
}
.content >>> .ant-col-12 {
  width: 75%;
}
.content >>> .ant-checkbox-wrapper {
  margin-left: 75px;
}
.content >>> .ant-btn-primary {
  width: 350px;
  margin-top: 25px;
  height: 45px;
  font-size: 19px;
  line-height: 40px;
}
.content >>> .ant-col-offset-5 {
  margin-left: 0px !important;
}
.content >>> .ant-form-explain {
  font-size: 12px;
}
.title {
  font-size: 25px;
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}
</style>
