<template>
  <div class="content">
    <div class="header">
      <div class="title">
        <div class="name">{{currentRouter}}</div>
        <div class="exclusive">
          <div class="account">{{ users.username }}</div>
          <div class="logout" @click="logout">退出登陆</div>
        </div>
      </div>
    </div>
    <div class="cont">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import jurisdictionList from '../../jurisdictionList'
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      users: "",
      isHospital: false,
      currentRouter: '首页',
      jurisdictionList: jurisdictionList,
    };
  },
  watch: {
    $route: {
      handler() {
        console.log(this.$route);
        const item = this.jurisdictionList.find(obj => obj.key === this.$route.name);
        this.currentRouter = item ? item.title : '';
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.users = storage.getItem("users");
    console.log(this.users)
    if (this.users && this.users.hospital) {
      this.isHospital = true;
      // this.getHospital(this.users.hospital);
    } else {
      this.isHospital = false;
    }

    // 超级管理员查看医院列表
    if (this.users && this.users.role_name == "admin") {
      this.getHospital();
    }
  },
  methods: {
    // 获取医院列表
    getHospital() {
      api.getHospitals().then((res) => {
        storage.setItem("hospitalList", res.data);
      });
    },
    logout() {
      api.logout().then(() => {
        storage.clear();
        this.$router.options.routes.splice(3, 1);
        this.$router.push("/");
        this.$message.success("退出成功");
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  position: relative;
  color: #000000;
  height: 100vh;
}
.content::before {
  content: " ";
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #29357b;
  z-index: 0;
}

.content::after {
  content: " ";
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #29357b;
  z-index: 0;
}

.header {
  width: 100%;
  height: 65px;
  background: #f7f8fa;
}

.title {
  width: 100%;
  height: 65px;
  position: absolute;
  z-index: 1;
  display: flex;
  background: #fff;
  border-radius: 30px 30px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 20px;
}
.cont {
  width: 100%;
  height: calc(100vh - 65px);
  background: #f7f8fa;
  position: absolute;
  border-radius: 0 0 30px 30px;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 20px;
}
.name {
  font-size: 16px;
}
.exclusive {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.logout {
  cursor: pointer;
  margin-left: 20px;
}
.logout:hover {
  color: #29357b;
}
</style>
